@use '~/src/sass/abstracts/utils' as *;
@use '~/src/sass/abstracts/variables/colors';
@use '~/src/sass/abstracts/variables/structure';

.resource__wrapper {
	position: relative;
	display: grid;
	grid-template-columns: minmax(1rem, 1fr);
	row-gap: 3rem;
	padding: 3rem 4rem 4rem 3rem;
	background-color: colors.$grey-100;
	border-radius: 20px;
}

.resource__content {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	align-items: flex-start;
	justify-content: space-between;
	height: fit-content;
	font-weight: 900;
	text-transform: uppercase;
}

.resource-infos {
	display: grid;
	grid-template-columns: minmax(1rem, 1fr);
	gap: 1rem;

	&__title {
		@extend %font-sm;

		font-weight: 900;
		color: colors.$grey-400;
	}

	&__subtitle {
		@extend %font-lg;

		margin: 0 0 2rem;
		font-weight: 900;
		line-height: 1.1;
		color: colors.$blue;
	}
}

.resource-btn {
	i {
		color: colors.$blue !important;
	}
}

.resource__image {
	margin-top: -10%;
}

@media screen and (min-width: structure.$tablet-landscape) {
	.resource {
		padding-top: 8rem;
	}

	.resource__wrapper {
		grid-template-columns: minmax(1rem, 3fr) minmax(1rem, 7fr);
		row-gap: 0;
		column-gap: 4.5rem;
		align-items: center;
		padding: 3rem 4rem 6rem 3rem;
	}

	.resource__image {
		position: absolute;
		bottom: 0;
		left: 2rem;
		width: auto;
		height: 24rem;
		margin-top: 0;
	}

	.resource__content {
		flex-direction: row;
		flex-grow: 1;
		grid-column: 2 / -1;
		column-gap: 4rem;
		align-items: center;
	}

	.resource-infos {
		align-items: flex-start;
		justify-content: space-between;

		&__title {
			margin: 0 !important;
		}

		&__subtitle {
			margin-bottom: 0;
		}
	}
}
